import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../layouts/Layout"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"

const WhyHireMe = ({ data }) => {
  const titleClassnames =
    "text-2xl leading-tight font-bold tracking-tight text-gray-900 sm:text-3xl text-center"
  return (
    <Layout>
      <SiteMetadata
        title="Por que me contratar | Pri Bellafronte"
        description="Meu nome é Pri Bellafronte, sou designer gráfico e esta página explica por que me contratar"
      />

      <div>
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="w-full md:w-2/12 pb-8 md:pb-0 m-auto">
                <GatsbyImage
                  image={data.author.childImageSharp.gatsbyImageData}
                  alt="Pri Bellafronte"
                  className="rounded-full shadow-md"
                />
              </div>
            </div>
          </div>
          <div className="w-full mt-12">
            <div className="w-full md:w-8/12 pb-8 md:pb-0 m-auto">
              <p>
                Olá! Meu nome é Priscilla Bellafronte. Sou designer gráfico com
                anos de experiência e muitos clientes satisfeitos e estou pronta
                para te ajudar.
              </p>
              <p className="mt-6">
                Gosto de me dedicar para que o seu projeto fique com a sua cara,
                com um nível de detalhe e qualidade incomparáveis. Não aceito
                mais projetos do que consigo entregar, não terceirizo. Estou em
                constante evolução e aprendizado, e meu objetivo final é sempre
                que você saia com uma marca que vai te ajudar a conseguir mais
                clientes, se destacar de seus concorrentes e passar um ar muito
                mais profissional e chamativo.
              </p>
            </div>
          </div>

          <div className="w-full mt-12">
            <div className="w-full md:w-8/12 pb-8 md:pb-0 m-auto">
              <h2 className={titleClassnames}>Depoimentos</h2>
              <p className="mt-6">
                Trabalho de qualidade se reflete em reconhecimento de clientes.
                Abaixo alguns depoimentos de clientes satisfeitos:
              </p>

              <div className="flex mt-4">
                <div className="md:w-6/12 bg-gray-200 mr-2 p-4">
                  Profissional super criativa, competente e responsável. Sua
                  sensibilidade e profissionalismo foram essenciais para
                  conseguir abstrair e expressar a essência do meu trabalho a
                  uma bela e moderna marca! É uma arte quando você consegue ver
                  sua identidade projetada num símbolo! Muito obrigada!
                  <br />
                  <br />
                  <span className="italic font-bold">
                    Rose Daise Nascimento - Psicóloga
                  </span>
                </div>
                <div className="md:w-6/12 bg-gray-200 p-4">
                  Priscilla é uma designer de altíssimo nível. Já trabalhei com
                  diversos designers, mas a Priscilla é aquela que sabe captar a
                  essência de um briefing. Como profissional ela sabe o que uma
                  marca deve comunicar ao público. Estou muito satisfeito e
                  agradecido com a criação da logomarca Dandan Farm pela
                  Priscilla. Muito obrigado!
                  <br />
                  <br />
                  <span className="italic font-bold">
                    Zalmir de Moura - Dono - Dandan Farm
                  </span>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="md:w-6/12 bg-gray-200 mr-2 p-4">
                  Acredito que você deve escutar muito isso, pelo nível de
                  talento do seu trabalho, mas vai lá: me emocionei com a
                  apresentação da marca! De verdade! O olho suou! Impressionante
                  o que você fez! Superou todas as expectativas que eu tinha!!
                  Só tenho a te parabenizar e te agradecer! Captou bem a
                  essência do que lhe repassei!. Fez nossa marca “criar vida”.
                  Espero corresponder à altura da sua marca agora. Obrigado de
                  verdade
                  <br />
                  <br />
                  <span className="italic font-bold">
                    Vinicius Teodoro - Dono - Cântico Café
                  </span>
                </div>
                <div className="md:w-6/12 bg-gray-200 p-4">
                  Perfeita, incrível, impecável. Estamos apaixonados pela nossa
                  marca ❤️
                  <br />
                  <br />
                  <span className="italic font-bold bottom-0">
                    Joeli Carvalho - Joeli Carvalho, Personal Chef
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-12">
            <div className="w-full md:w-10/12 pb-8 md:pb-0 m-auto">
              <h2 className={titleClassnames}>Casos de sucesso</h2>
              <div className="flex mt-4">
                <div className="md:w-6/12 p-4">
                  <img
                    alt="Marca Cântico Café em copo"
                    src="/cantico-1.jpg.webp"
                  />
                </div>
                <div className="md:w-6/12 p-4">
                  <img
                    alt="Marca Joeli Personal Chef"
                    src="/joeli_carvalho_personal_chef_6.webp"
                  />
                </div>
              </div>
              <div className="flex mt-4">
                <div className="md:w-6/12 p-4">
                  <img
                    alt="Marca Muzambo Café em embalagem e copo"
                    src="/muzambo-1.webp"
                  />
                </div>
                <div className="md:w-6/12 p-4">
                  <img
                    alt="Marca Sabhyá em cartão de visita"
                    src="/sabhya-1.webp"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-12">
            <div className="w-full md:w-12/12 pb-8 md:pb-0 m-auto">
              <h2 className={titleClassnames}>Antes e depois</h2>
              <img
                alt="Marca Sabhyá em cartão de visita"
                src="/next-antes-e-depois.png"
              />
            </div>
          </div>
        </div>
        <div className="py-12 px-4 lg:py-16">
          <PageFooter />
        </div>
      </div>
    </Layout>
  )
}

export default WhyHireMe

export const query = graphql`
  {
    author: file(relativePath: { eq: "pri-sobre-mim.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 480
          quality: 85
          layout: CONSTRAINED
        )
      }
    }
  }
`
